<template lang="">
    <div>
 


        <b-list-group class="m-3" >
            <b-list-group-item >
                 <b-row>
                    <b-col md="1">ລຳດັບ</b-col>
                    <b-col md="2">ຊື່ ແລະ ນາມສະກຸນ</b-col>
                     <b-col md="2">ວັນເດືອນປີເກິດ, ອາຍຸ</b-col> 
                    <b-col md="3">ວັນທີ, ເວລານັດ</b-col>
                    <b-col md="1">QR</b-col>
                </b-row>
            </b-list-group-item>
           
            <b-list-group-item  v-for="(item,index) in items" :key="item.queue_id">
                
                <b-row>
                    <b-col md="1"> {{index+1}} </b-col>
                    <b-col md="2">{{item.fname}} {{item.lname}}</b-col>
                     <b-col md="2">{{item.dob |formatDate }}, {{item.age}} ປີ</b-col> 
                    <b-col md="3" class="text-success">{{item.queue_date | dateString }} </b-col>
                    <b-col md="1"> <b-link :to="'/qr/'+item.queue_id">QR</b-link> <b-icon src="../assets/icons8-qr-code-48.png"></b-icon> </b-col>
                    <b-col v-if="item.status=='ເອກະສານບໍ່ຜ່ານ'" class="text-danger" >  {{item.status}}</b-col>
                    <b-col v-if="item.status=='ເຂົ້າສຳພາດແລ້ວ'" class="text-success" >  {{item.status}}</b-col>
                </b-row>
            </b-list-group-item>
        </b-list-group>
        <!-- <infinite-loading @infinite="infiniteHandler" spinner="spiral">
            <div slot="spinner">Loading...</div>
            <div slot="no-more">No more data</div>
            <div slot="no-results">No results data</div>
        </infinite-loading> -->
    
    </div>
</template>

<script>
import axios from "axios";
//import InfiniteLoading from "vue-infinite-loading";
export default {
    data() {
        return {
            fname: "",
            lname: "",
            page: 0,
            field: '',
            find: '',
            menuid: this.$route.query.id,
            pkey: "",
            table: "",
            fields: [],
            items: [],
        }

    },

    components: {
        // InfiniteLoading
    },
    created() {
        axios
            .get(
                this.$store.getters.getBaseUrl +
                "/v_queue_details/queue_date/" + this.$route.query.queue_date1,
                {
                    headers: {
                            "Content-Type": "application/json",
                            Authorization: 'Bearer anUrbLQdEVK7V/w3Mjkp28ZDJXgDH7SpKeqtKBjkqMnNJT4OQ?ukI8w7M5/HmxsONU78KEg4p=!5r0WOf=5/zBa9h/PTI42lb5JEhR855=LTAt!!AeJBFkmFtrpOwS7KhMuk!=B=8Rn=aqz6U59ZoPATYEKFkaZ-C/4ZRUXjUfb4DA0-69KsueEHkI/RW8mD2lLudpbe5JZpti=uQmQe5Nq2G6pP9//DX9hGm3yFpVMInuh-O50nbYdMej1miJTs',
                            "Accept-Encoding": "identity"
                        }
                }
            )
            .then((response) => {
                this.items = response.data;
            })


    },
    methods: {
        // infiniteHandler($state) {
        //     this.busy = true;
        //     setTimeout(() => {

        //         axios
        //             .get(
        //                 this.$store.getters.getBaseUrl +
        //                 "/listqueue" +
        //                 "?offset=" + this.page + "&fld=" + this.field + "&find=" + this.find
        //             )

        //             .then((response) => {
        //                 //this.pkey=response.data.pkey
        //                 if (this.page == 0) {
        //                     // this.fields = JSON.parse(response.data.fields)

        //                     // this.fields.forEach((item) => {
        //                     //     this.findfied.push({
        //                     //         value: item.key,
        //                     //         text: item.label
        //                     //     })
        //                     // })

        //                     this.items = response.data.items;
        //                     this.page++;

        //                 } else {

        //                     if (response.data.items.length != 0) {
        //                         //    this.page++;
        //                         this.items = this.items.concat(response.data.items);
        //                         this.page++;
        //                         $state.loaded();
        //                     } else {
        //                         $state.complete();
        //                         $state.loaded();
        //                     }
        //                     $state.loaded();

        //                 }

        //                 // if (response.data.length > 1) {
        //                 //     response.data.forEach(item => this.items.push(item));
        //                 //     $state.loaded();
        //                 // } else {
        //                 //     $state.complete();
        //                 //     $state.loaded();
        //                 // }

        //             });
        //         //  $state.loaded();
        //         this.busy = false;
        //     }, 500);
        // },


        onFinditems() {

            axios
                .get(
                    this.$store.getters.getBaseUrl +
                    "/findqueue?fname=" + this.fname + "&lname=" + this.lname,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: 'Bearer anUrbLQdEVK7V/w3Mjkp28ZDJXgDH7SpKeqtKBjkqMnNJT4OQ?ukI8w7M5/HmxsONU78KEg4p=!5r0WOf=5/zBa9h/PTI42lb5JEhR855=LTAt!!AeJBFkmFtrpOwS7KhMuk!=B=8Rn=aqz6U59ZoPATYEKFkaZ-C/4ZRUXjUfb4DA0-69KsueEHkI/RW8mD2lLudpbe5JZpti=uQmQe5Nq2G6pP9//DX9hGm3yFpVMInuh-O50nbYdMej1miJTs',
                            "Accept-Encoding": "identity"
                        }
                    }
                )
                .then((response) => {
                    this.items = response.data.items;
                })
        }

    },
    filters: {
        dateString(dd) {
            let op = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
            const d = new Date(dd);

            return d.toLocaleString('lao', op);
        },

        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        formatDate(dd) {
            let d = new Date(dd)
            return [
                (d.getDate().toString().padStart(2, '0')),
                (d.getMonth() + 1).toString().padStart(2, '0'),
                d.getFullYear(),
            ].join('/');
        },
    }

}
</script>

<style lang="">

</style>